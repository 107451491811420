export class SortItem {
}
export var SortType;
(function (SortType) {
    // 不排序
    SortType[SortType["None"] = 0] = "None";
    // 正序
    SortType[SortType["Asc"] = 1] = "Asc";
    // 倒序
    SortType[SortType["Desc"] = 2] = "Desc";
})(SortType || (SortType = {}));
