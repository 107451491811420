import { defineComponent } from "vue";
import { ShopProduction } from "@/api/GoShopApi";
import CategoryService from "@/services/Goods/CategoryService";
export default defineComponent({
    emits: ["goDetail", "onSelectAndPublish", "onSelectAndNotPublish"],
    props: {
        data: {
            type: Object,
            default: () => {
                return new ShopProduction();
            },
            required: true,
        },
        isSelf: {
            type: Boolean,
        },
    },
    setup(props, { emit }) {
        const goDetail = () => {
            emit("goDetail");
        };
        const onSelectAndPublish = () => {
            emit("onSelectAndPublish");
        };
        const onSelectAndNotPublish = () => {
            emit("onSelectAndNotPublish");
        };
        const cateName = (id) => {
            return CategoryService.GetCatagoryNameById(id);
        };
        return { goDetail, onSelectAndPublish, onSelectAndNotPublish, cateName };
    },
});
