import { GetShareProList } from "@/api/GoShopApi";
import { SortAllType, SortModel } from "@/common/enum";
import { ScrollToTop } from "@/common/utils";
import { SortType } from "@/components/sortBar/SortBarExtend";
import CategoryService from "@/services/Goods/CategoryService";
import { reactive, ref } from "vue";
export default function ProdQuery() {
    const allTypes = reactive(new Array());
    const cateResult = CategoryService.GetAllCatagory();
    const sortItems = reactive(new Array());
    const name = ref("");
    const proList = reactive(new Array());
    const page = ref(1);
    const pageSize = ref(20);
    const totalCount = ref(0);
    const loading = ref(false);
    const productCategoryId = ref(0);
    allTypes.splice(0, allTypes.length);
    cateResult.forEach((item) => {
        allTypes.push(item);
    });
    const queryData = async () => {
        const args = {
            name: name.value.replace(/\+/g, '%2B'),
            productCategoryId: productCategoryId.value || "",
            pageNum: page.value,
            pageSize: pageSize.value,
            sortType: getSortType()
        };
        loading.value = true;
        const res = await GetShareProList(args);
        initProds(res.resultData);
        loading.value = false;
        ScrollToTop();
    };
    const initProds = (data) => {
        if (data.list) {
            proList.splice(0, proList.length);
            data.list.forEach((x) => {
                proList.push(x);
            });
            totalCount.value = data.total;
        }
    };
    const setSortItems = () => {
        sortItems.splice(0, sortItems.length);
        sortItems.push({
            id: SortModel.CreateTime,
            text: "入库时间",
            sortType: SortType.Desc,
        });
        sortItems.push({
            id: SortModel.Price,
            text: "销售价格",
            sortType: SortType.None,
        });
        sortItems.push({
            id: SortModel.Commission,
            text: "佣金",
            sortType: SortType.None,
        });
    };
    const getSortType = () => {
        for (let i = 0; i < sortItems.length; i++) {
            if (sortItems[i].sortType == SortType.None) {
                continue;
            }
            if (sortItems[i].id == SortModel.CreateTime) {
                if (sortItems[i].sortType == SortType.Asc) {
                    return SortAllType.CreateTimeAsc;
                }
                return SortAllType.CreateTimeDesc;
            }
            if (sortItems[i].id == SortModel.Price) {
                if (sortItems[i].sortType == SortType.Asc) {
                    return SortAllType.SalePriceAsc;
                }
                return SortAllType.SalePriceDesc;
            }
            if (sortItems[i].id == SortModel.Commission) {
                if (sortItems[i].sortType == SortType.Asc) {
                    return SortAllType.CommissionAsc;
                }
                return SortAllType.CommissionDesc;
            }
        }
        return SortAllType.CreateTimeDesc;
    };
    const handleSizeChange = (val) => {
        pageSize.value = val;
        queryData();
    };
    const handleCurrentChange = (val) => {
        page.value = val;
        queryData();
    };
    const onSortChanged = (val) => {
        sortItems.forEach(x => {
            if (x.id == val.item.id) {
                x.sortType = val.sortType;
            }
            else {
                x.sortType = SortType.None;
            }
        });
        queryData();
    };
    const onCateChanged = (val) => {
        productCategoryId.value = val.id;
    };
    return {
        allTypes,
        onCateChanged,
        proList,
        setSortItems,
        handleSizeChange,
        handleCurrentChange,
        pageSize,
        totalCount,
        sortItems,
        name,
        onSortChanged,
        queryData,
        loading
    };
}
