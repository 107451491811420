import { defineComponent } from "vue";
import { SortType } from "./SortBarExtend";
export default defineComponent({
    emits: ["sortChanged"],
    props: {
        items: {
            type: Array,
            default: () => {
                return [];
            },
            required: true,
        },
    },
    setup(props, { emit }) {
        const isAsc = (item) => {
            return item.sortType == SortType.Asc;
        };
        const isDesc = (item) => {
            return item.sortType == SortType.Desc;
        };
        const toAsc = (item) => {
            emit("sortChanged", { item: item, sortType: SortType.Asc });
        };
        const toDesc = (item) => {
            emit("sortChanged", { item: item, sortType: SortType.Desc });
        };
        const changedSortType = (item) => {
            if (item.sortType == SortType.Desc) {
                toAsc(item);
                return;
            }
            toDesc(item);
        };
        return { isAsc, isDesc, toAsc, toDesc, changedSortType };
    },
});
